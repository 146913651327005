"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.escapeChat = escapeChat;
exports.unescapeChat = unescapeChat;
exports.safeTags = safeTags;
exports.decodeChat = decodeChat;
exports.prepChat = prepChat;
/**
 * Escapes a string to AO1 escape codes.
 * @param {string} estring the string to be escaped
 */
function escapeChat(estring) {
    return estring
        .replaceAll("#", "<num>")
        .replaceAll("&", "<and>")
        .replaceAll("%", "<percent>")
        .replaceAll("$", "<dollar>");
}
/**
 * Unescapes a string to AO1 escape codes.
 * @param {string} estring the string to be unescaped
 */
function unescapeChat(estring) {
    return estring
        .replaceAll("<num>", "#")
        .replaceAll("<and>", "&")
        .replaceAll("<percent>", "%")
        .replaceAll("<dollar>", "$");
}
/**
 * Escapes a string to be HTML-safe.
 *
 * XXX: This is unnecessary if we use `createTextNode` instead!
 * @param {string} unsafe an unsanitized string
 */
function safeTags(unsafe) {
    if (unsafe) {
        return unsafe.replaceAll(">", "＞").replaceAll("<", "＜");
    }
    return "";
}
/**
 * Decodes text on client side.
 * @param {string} estring the string to be decoded
 */
function decodeChat(estring) {
    // Source: https://stackoverflow.com/questions/7885096/how-do-i-decode-a-string-with-escaped-unicode
    return estring.replace(/\\u([\d\w]{1,})/gi, function (match, group) {
        return String.fromCharCode(parseInt(group, 16));
    });
}
/**
 * XXX: a nasty hack made by gameboyprinter.
 * @param {string} msg chat message to prepare for display
 */
function prepChat(msg) {
    // TODO: make this less awful
    return safeTags(unescapeChat(decodeChat(msg)));
}
